/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import 'font-awesome-css';

import 'regenerator-runtime/runtime';
import { LogManager } from 'aurelia-framework';
import { bootstrap } from 'aurelia-bootstrapper-webpack';
import { logLevel } from 'aurelia-logging';
import { Config } from '@lp/config';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import smoothscroll from 'smoothscroll-polyfill';
import getIntegrationsConfig from '@lp/integrations-manager/dist/config';
import ensureIntegrationsDockerSetup from '@lp/integrations-manager/dist/ensure-docker-integrations-setup';

import getBuilderConfig from '../config/index.js';

import '@lp/tailor';
import './loading';
import { sentryLogAppender } from './services/vendor/track-service';
import '../styles/styles.scss';
import '../styles/page-styles.scss';
import '@lp/animate.css';

import packageJson from '../package.json';

function setupPromiseHandlers() {
  // this will stop exceptions from being thrown for unhandled promise rejections,
  // which are a huge pain in the butt to track down
  window.onunhandledrejection = function setupPromiseHandlersOnUnhandledRejection(e) {
    // eslint-disable-next-line no-console
    console.log('unhandled', e.reason, e.promise);
  };
  window.onrejectionhandled = function setupPromiseHandlersOnRejectionHandled(e) {
    // eslint-disable-next-line no-console
    console.log('handled', e.reason, e.promise);
  };
}

function setupSentry() {
  const config = Config.getInstance();
  const dsn = config.get('SENTRY_DSN');
  if (!dsn) return;

  const integrations = [];
  const fsOrgId = config.get('FULLSTORY_ORG_ID');
  if (fsOrgId) integrations.push(new SentryFullStory('leadpages'));

  window.sentryEventCount = 0;

  Sentry.init({
    dsn,
    integrations,
    environment: config.get('ENVIRONMENT', ''),
    release: `${packageJson.name}@${packageJson.version}`,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    blacklistUrls: [/sleeknotestaticcontent\.sleeknote\.com/i],
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.message &&
        breadcrumb.message.toString().includes('rs.fullstory.com/rec/bundle')
        ? null
        : breadcrumb;
    },
    beforeSend(event) {
      try {
        window.sentryEventCount += 1;
        if (window.sentryEventCount > 50) {
          return null;
        }
      } catch (e) {
        // Bury.
      }
      return event;
    },
  });
}

function setupFullStory() {
  const config = Config.getInstance();
  const orgId = config.get('FULLSTORY_ORG_ID');
  if (!orgId) return;

  FullStory.init({ orgId });
}

function setupMixpanel() {
  const config = Config.getInstance();
  const mixpanelToken = config.get('MIXPANEL_TOKEN');

  if (!mixpanelToken) return;

  /* eslint-disable */
  // prettier-ignore
  (function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
  0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
  for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
  MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
  mixpanel.init(mixpanelToken, {
    record_sessions_percent: config.get('MIXPANEL_SESSION_REPLAY_PERCENTAGE'),
    record_max_ms: config.get('MIXPANEL_SESSION_REPLAY_LENGTH'),
    record_mask_text_selector: config.get('MIXPANEL_SESSION_REPLAY_TEXT_MASK'),
  });
}

function setupHubSpot() {
  const config = Config.getInstance();
  const portalID = config.get('HUBSPOT_PORTAL_ID');

  if (portalID) {
    (function() {
      var dc = document.createElement('script');
      dc.type = 'text/javascript';
      dc.async = true;
      dc.src = `//js.hs-scripts.com/${portalID}.js`;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(dc, s);
    })();
  }
}

function injectStripe() {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.crossorigin = 'anonymous';

  script.innerHTML = `
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://js.stripe.com/v3/";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'stripe'));
  `;

  head.appendChild(script);
}

bootstrap(aurelia => {
  const environment = process.env.CONFIG_ENV;
  const config = Config.getInstance();
  const builderConfig = getBuilderConfig(environment);
  config.loadConfig(builderConfig);

  const integrationsConfig = getIntegrationsConfig(environment);
  config.loadConfig(integrationsConfig);

  const isAdminLoggedInAsUser = document.cookie.indexOf('as_user_id') !== -1;
  if (!isAdminLoggedInAsUser) {
    setupMixpanel();
    setupHubSpot();
  }

  setupSentry();
  setupFullStory();
  injectStripe();

  if (environment === 'docker') ensureIntegrationsDockerSetup();

  if (config.get('DEBUG')) {
    LogManager.setLevel(logLevel.debug);
    aurelia.use.developmentLogging();
  } else {
    setupPromiseHandlers(); // swallow promise errors
    LogManager.setLevel(logLevel.error);
    if (config.get('SENTRY_DNS')) {
      LogManager.addAppender(sentryLogAppender);
    }
  }

  // TODO: Remove when native smooth scrolling has wider browser support.
  // https://caniuse.com/#feat=css-scroll-behavior
  smoothscroll.polyfill();

  if (module.hot) {
    module.hot.accept();
  }

  aurelia.use
    .standardConfiguration()
    .plugin('aurelia-dialog')
    .plugin('aurelia-animator-css')
    .plugin('resources')
    .plugin('react-component-plugin');

  aurelia.start().then(() => aurelia.setRoot('app', document.querySelector('#lp-builder')));
});
